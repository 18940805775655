$(document).ready(function () {

    // --------------  Products Index page ---------------
    var equipment_generation_groups_grid_index_page = document.querySelector('#equipment_generation_groups_grid');

    if (equipment_generation_groups_grid_index_page != undefined) {

        var dictionary = {
            ru: {
                created_at: 'Создан',
                name_en: "Название",
                series_name: "Серия",
                manufacturer_name_en: "Производитель",
                model_name: "Модель"
            },
            en: {
                created_at: 'Created',
                name_en: "Name",
                series_name: "Series",
                manufacturer_name_en: "Manufacturer",
                model_name: "Model"
            }
        };

        function getColumnNameByCulture(culture, word) {
            // console.log(user_report_dictionary[culture][word])
            return dictionary[culture][word]
        }

        function resize_window() {
            $("#equipment_generation_groups_grid").children(".k-grid-content").height($(window).height() - 250);
        }

        $(window).resize(function () {
            resize_window();
        });

        function table_row_coloring() {
            var grid = $("#products_grid").data("kendoGrid");
            var data = grid.dataSource.data();

            $.each(data, function (i, row) {

                if (row.is_active == true) {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#ddecd5");
                } else {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#f6b8c3");
                }

                if (row.is_active_by_product_manufacturer == false) {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#ffacbb");
                }

            });
        }





        var dataSource = new kendo.data.DataSource(
            {
                // type: "json",
                sort: {field: "created_at", dir: "desc"},
                transport: {
                    read: '/' + gon.locale + "/equipment_generation_groups.json"
                },
                group: [{
                    title: 'Organization',
                    field: "organization_name",
                    dir: "asc",
                }],
                filter: {logic: "and", filters: []},
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name_en: {type: "string"},
                            series_id: {type: "integer"},
                            series_name_en: {type: "string"},
                            manufacturer_id: {type: "integer"},
                            manufacturer_name_en: {type: "string"},
                            created_at: {type: "date"},
                            model_name: {type: "string"}
                        }
                    }
                },
                pageSize: 30,
                total: function (response) {
                    return response[0].TotalRecords;
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true
            }
        );

        $("#equipment_generation_groups_grid").kendoGrid({
            dataSource: dataSource,

            dataBound: function (e) {
                resize_window();
                // table_row_coloring();
            },
            sortable: true,
            pageable: {
                refresh: true,
                // messages: {
                //     itemsPerPage: "",
                //     display: "{0} - {1} " + gon.i18n_samples_from + " {2} " + gon.i18n_samples
                // }
            },
            // pageable: true,
            navigatable: true,
            reorderable: true,
            resizable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-gray-100 hover:bg-gray-200" style="font-size: 11px;" href="/#= gon.locale #/equipment_generation_groups/#=id#">Show</i></a></div>',
                    width: 50
                },
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-yellow-100 hover:bg-yellow-200" style="font-size: 11px;" href="/#= gon.locale #/equipment_generation_groups/#=id#/edit">Edit</i></a></div>',
                    width: 50
                },
                {
                    title: getColumnNameByCulture(gon.locale, 'created_at'),  //"Дата создания",
                    field: "created_at",
                    // filterable: {
                    //     cell: {
                    //         showOperators: false
                    //     }
                    // },
                    format: "{0:dd.MM.yyyy | HH:mm:ss}",
                    // format: "{0:dd.MM.yyyy}",
                    type: "date",
                    filterable: false,
                    width: 150
                },
                {
                    title: getColumnNameByCulture(gon.locale, 'name_en'),
                    field: "name_en",
                    template: '<div class="px-1">#= data.name_en #</div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 180
                },
                {
                    title: getColumnNameByCulture(gon.locale, 'series_name'),
                    field: "series_name_en",
                    template: '<div class="px-1">#= data.series_name_en #</div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
                {
                    title: getColumnNameByCulture(gon.locale, 'manufacturer_name_en'),  //
                    field: "manufacturer_name_en",
                    template: '<div class="px-1">#= data.manufacturer_name_en #</div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
                {
                    title: getColumnNameByCulture(gon.locale, 'model_name'),  //
                    field: "model_name",
                    template: '<div class="px-1">#= data.model_name #</div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                }


            ]
        });

    }

});