var equipment_manufacturer_id = null;

$(document).ready(function () {

    // --------------  Equipment Edit Page ---------------
    var equipment_edit_page = document.querySelector('#equipment_edit_page');

    if (equipment_edit_page != undefined) {

        $("#equipment_category_id").kendoDropDownList({
            filter: "startswith",
            dataTextField: "name_en",
            dataValueField: "id",
            optionLabel: {
                name_en: gon.equipment_category_name_en,
                id: gon.equipment_category_id
            },
            dataSource: {
                serverFiltering: true,
                transport: {
                    read: {
                        dataType: "json",
                        url: "/categories.json",
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name_en: {type: "string"},
                        }
                    }
                },
            }
        });


        $("#equipment_manufacturer_id").kendoDropDownList({
            filter: "startswith",
            dataTextField: "name_en",
            dataValueField: "id",
            optionLabel: {
                name_en: gon.equipment_manufacturer_name_en,
                id: gon.equipment_manufacturer_id
            },
            select: function (e) {
                var dataItem = this.dataItem(e.item);

                if (dataItem) {
                    equipment_manufacturer_id = dataItem.id;
                    console.log(equipment_manufacturer_id);

                    var equipment_series = $("#series_name_en").data("kendoComboBox");

                    var newFilter = [
                        {
                            field: "equipment_manufacturer_id", operator: "eq", value: function () {
                                return equipment_manufacturer_id
                            }
                        },
                    ];

                    equipment_series.dataSource.filter(newFilter);
                    equipment_series.refresh();
                    
                }
            },
            dataSource: {
                serverFiltering: true,
                transport: {
                    read: {
                        dataType: "json",
                        url: "/manufacturers.json",
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name_en: {type: "string"},
                        }
                    }
                },
            }
        });


        $("#series_name_en").kendoComboBox({
            filter: "startswith",
            dataTextField: "name_en",
            dataValueField: "name_en",
            autoBind: true,
            value: gon.equipment_series_name_en,
            // optionLabel: {
            //     name_en: gon.equipment_series_name_en,
            //     id: gon.equipment_series_id
            // },
            dataSource: {
                serverFiltering: true,
                transport: {
                    read: {
                        dataType: "json",
                        url: "/series.json",
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name_en: {type: "string"},
                        }
                    }
                },
            }
        });

        $("#equipment_generation_group_name_en").kendoComboBox({
            filter: "startswith",
            dataTextField: "name_en",
            dataValueField: "name_en",
            autoBind: true,
            value: gon.equipment_generation_group_name_en,
            // optionLabel: {
            //     name_en: gon.equipment_series_name_en,
            //     id: gon.equipment_series_id
            // },
            dataSource: {
                serverFiltering: true,
                transport: {
                    read: {
                        url: "/equipment_generation_groups.json",
                        data: {
                            filter: {
                                logic: "and",
                                custom_filters: [
                                    {field: "series_id", operator: "eq", value: function() {return gon.equipment_series_id }}
                                ]
                            }
                        }
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            series_id: {type: "integer"},
                            name_en: {type: "string"},
                        }
                    }
                },
            }
        });



        $("#equipment_fuel_type_id").kendoDropDownList({
            filter: "startswith",
            dataTextField: "name_en",
            dataValueField: "id",
            optionLabel: {
                name_en: gon.equipment_fuel_type_name_en,
                id: gon.equipment_fuel_type_id
            },
            dataSource: {
                serverFiltering: true,
                transport: {
                    read: {
                        dataType: "json",
                        url: "/fuel_types.json",
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name_en: {type: "string"},
                        }
                    }
                },
            }
        });



        // $("#equipment_series_id").kendoDropDownList({
        //     filter: "startswith",
        //     dataTextField: "name_en",
        //     dataValueField: "id",
        //     optionLabel: {
        //         name_en: gon.equipment_series_name_en,
        //         id: gon.equipment_series_id
        //     },
        //     dataSource: {
        //         serverFiltering: true,
        //         transport: {
        //             read: {
        //                 dataType: "json",
        //                 url: "/series.json",
        //             }
        //         },
        //         schema: {
        //             total: "total",
        //             data: "results",
        //             id: "id",
        //             model: {
        //                 fields: {
        //                     id: {type: "integer"},
        //                     name_en: {type: "string"},
        //                 }
        //             }
        //         },
        //     }
        // });

        $("#equipment_product_group_id").kendoDropDownList({
            filter: "startswith",
            dataTextField: "name_en",
            dataValueField: "id",
            optionLabel: {
                name_en: gon.equipment_product_group_name_en,
                id: gon.equipment_product_group_id
            },
            dataSource: {
                serverFiltering: true,
                transport: {
                    read: {
                        dataType: "json",
                        url: "/product_groups.json",
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name_en: {type: "string"},
                        }
                    }
                },
            }
        });


        // $("#equipment_engine_size_id").kendoDropDownList({
        //     filter: "startswith",
        //     dataTextField: "engine_size_s",
        //     dataValueField: "id",
        //     optionLabel: {
        //         name_en: gon.equipment_engine_size_s,
        //         id: gon.equipment_engine_size_id
        //     },
        //     dataSource: {
        //         serverFiltering: true,
        //         sort: {field: "engine_size_s", dir: "asc"},
        //         transport: {
        //             read: {
        //                 dataType: "json",
        //                 url: "/engine_sizes.json",
        //             }
        //         },
        //         schema: {
        //             total: "total",
        //             data: "results",
        //             id: "id",
        //             model: {
        //                 fields: {
        //                     id: {type: "integer"},
        //                     engine_size_s: {type: "string"},
        //                 }
        //             }
        //         },
        //     }
        // });


        $("#equipment_drive_type_id").kendoDropDownList({
            filter: "startswith",
            dataTextField: "name",
            dataValueField: "id",
            optionLabel: {
                name_en: gon.equipment_drive_type_name_en,
                id: gon.equipment_drive_type_id
            },
            dataSource: {
                serverFiltering: true,
                transport: {
                    read: {
                        dataType: "json",
                        url: "/drive_types.json",
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name: {type: "string"},
                        }
                    }
                },
            }
        });

        $("#equipment_region_ids").kendoMultiSelect({
            placeholder: "Please Select a Region",
            dataTextField: "name_en",
            dataValueField: "id",
            autoBind: false,
            height: 500,
            value: gon.regions,
            dataSource: {
                serverFiltering: true,
                sort: {field: "name", dir: "asc"},
                transport: {
                    read: {
                        url: "/regions.json",
                        data: {
                            filter: {
                                logic: "and",
                                filters: [
                                    {field: "paging_enable", operator: "eq", value: true}
                                ]
                            }
                        }
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name_en: {type: "string"},
                            name_ru: {type: "string"},
                            created_at: {type: "date"},
                        }
                    }
                }
            }
        });

        // drive types
        $("#equipment_drive_type_ids").kendoMultiSelect({
            placeholder: "Please Select a Drive type",
            dataTextField: "name",
            dataValueField: "id",
            autoBind: false,
            height: 500,
            value: gon.drive_types,
            dataSource: {
                serverFiltering: true,
                sort: {field: "name", dir: "asc"},
                transport: {
                    read: {
                        url: "/drive_types.json",
                        data: {
                            filter: {
                                logic: "and",
                                filters: [
                                    {field: "paging_enable", operator: "eq", value: true}
                                ]
                            }
                        }
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name: {type: "string"},
                        }
                    }
                }
            }
        });


        // EQUIPMENT COPY ROUTINE
        // Show Modal copy equipment
        $("#equipment_copy_button").click(function (e) {
            e.preventDefault();
            $('#modal_window_equipment_copy').fadeTo(250, 1, function () {
                $(this).show()
            })
            $('#modal_window_equipment_copy_overlay').fadeTo(250, 1, function () {
                $(this).show()
            })
            // $("#application_zebra_label_input_text").focus();
        });

        function hide_modal_overlay(e) {
            e.preventDefault();

            $('#modal_window_equipment_copy_overlay').fadeTo(250, 0, function () {
                $(this).hide()
            })

            $('#modal_window_equipment_copy').fadeTo(250, 0, function () {
                $(this).hide()
            })
        }

        $("#modal_window_equipment_copy_cancel_button").click(function (e) {
            hide_modal_overlay(e);
        })

        $("#modal_window_equipment_copy_grayout").click(function (e) {
            hide_modal_overlay(e);
        })

        function equipment_check_is_copy() {
            $('#is_make_copy_of_equipment').prop('checked', true);
            return $.when('set copy');
        }

        function equipment_submit_update_form() {
            $('#equipment_form_save_button').trigger('click');
            return $.when('submit form');
        }

        $("#modal_window_equipment_make_copy_button").click(function (e) {
            e.preventDefault();

            equipment_check_is_copy().then(equipment_submit_update_form());

        });

    }

});